import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isEmpty, size } from 'lodash'
import queryString from 'qs'
import moment from 'moment'

import Grid from 'src/UIComponents/Grid/Grid'
import FilterSelectorsContainer from 'src/UIComponents/Filter/FilterSelectorsContainer'
import FilterActionsContainer from 'src/UIComponents/Filter/FilterActionsContainer'
import FilterHeader from 'src/UIComponents/Filter/FilterHeader'
import FilterContainer from 'src/UIComponents/Filter/FilterContainer'
import { SearchInput } from 'src/UIComponents/SearchInput'
import ButtonDropdown from 'src/UIComponents/ButtonDropdown/ButtonDropdown'
import Button from 'src/UIComponents/Button/ButtonDefault'
import { useSelector } from 'react-redux'
import TooltipComponent from './TooltipComponent'

const Filter = ({
  values,
  searchPlaceholder,
  onSearchSubmit,
  onSearchClear,
  filters,
  onFiltersApply,
  onFiltersClear,
  defaultSearch,
  noSearchInput,
  noDateRange,
  onAmountTypeChange,
  typeChange,
  handleIdTransaction,
  clearIdTransaction
}) => {
  const history = useHistory()
  const [state, setState] = useState(values)

  let defaultDateFilter = {
    date_range: {
      gte: moment().startOf('day').toDate(),
      lte: moment().endOf('day').toDate()
    }
  }

  if (noDateRange) {
    defaultDateFilter = {}
  }

  const { seller, marketplace } = useSelector(({ context }) => context)

  const filterRef = useRef({
    sellerId: seller?.id,
    marketplaceId: marketplace && marketplace.id
  })

  const handleFilterChange = (accessor) => (value) => {
    const filter = filters.find(({ accessor: a }) => a === accessor)

    if (filter && filter.transformer) {
      value = filter.transformer.parse(value)
    }

    if (value) {
      setState({
        ...state,
        [accessor]: value,
        accessorName: accessor
      })
    } else {
      const newState = { ...state }
      delete newState[accessor]
      setState(newState)
    }
  }

  const handleFiltersClear = (e) => {
    e.preventDefault()
    history.push({
      search: undefined
    })
    const keepType = {}

    if (state?.['amount_range[gte]'] === 0) {
      keepType['amount_range[gte]'] = state['amount_range[gte]']
    }

    if (state?.['amount_range[lte]'] === 0) {
      keepType['amount_range[lte]'] = state['amount_range[lte]']
    }

    if (state?.['blocked_amount_range[gte]'] === 1) {
      keepType['blocked_amount_range[gte]'] = state['blocked_amount_range[gte]']
    }
    setState({ ...keepType })
  }

  const handleFiltersApply = () => {
    // eslint-disable-next-line no-unused-vars
    const { accessorName, ...searchFilters } = {
      ...defaultDateFilter,
      ...state
    }

    const search = queryString.stringify(searchFilters, { arrayFormat: 'brackets', skipNull: true, skipEmptyString: true })
    history.push({
      search
    })

    onFiltersApply(state)
  }

  useEffect(() => {
    if (isEmpty(state)) {
      onFiltersClear()
    }
    handleFiltersApply()
  }, [JSON.stringify(state)])

  useEffect(() => {
    setState({
      ...defaultDateFilter,
      ...values
    })
  }, [values])

  useEffect(() => {
    if (filterRef.current.sellerId !== seller?.id || (marketplace && filterRef.current.marketplaceId !== marketplace.id)) {
      filterRef.current = {
        sellerId: seller?.id,
        marketplaceId: marketplace && marketplace.id
      }
      setState({})
      onFiltersClear()
    }
  }, [seller, marketplace])

  const buttonsDisabled = size(state) === 0

  return (
    <FilterContainer borderRadius={'0'}>
      <Grid noPadding>
        <Grid.Row>
          {!noSearchInput && (
            <Grid.Col cols={5}>
              <SearchInput defaultSearch={defaultSearch} placeholder={searchPlaceholder} onSubmit={onSearchSubmit} onClear={onSearchClear} />
            </Grid.Col>
          )}
          <Grid.Col cols={noSearchInput ? 16 : 11}>
            <FilterSelectorsContainer>
              {onAmountTypeChange && (
                <>
                  <>
                    <Button ghost={typeChange !== 'all'} onClick={() => onAmountTypeChange('all')}>
                      Todos
                    </Button>
                    <Button ghost={typeChange !== 'positive'} onClick={() => onAmountTypeChange('positive')}>
                      Entradas
                    </Button>
                    <Button ghost={typeChange !== 'negative'} onClick={() => onAmountTypeChange('negative')}>
                      Saidas
                    </Button>
                    <TooltipComponent id='button-agendadas' text='Disponíveis no próximo dia útil'>
                      <Button ghost={typeChange !== 'agendadas'} onClick={() => onAmountTypeChange('agendadas')}>
                        Agendadas
                      </Button>
                    </TooltipComponent>
                  </>
                  <FilterHeader>Procurar por:</FilterHeader>
                  <SearchInput defaultSearch={''} placeholder={'ID da transação'} onSubmit={handleIdTransaction} onClear={clearIdTransaction} />
                </>
              )}

              <FilterHeader>Filtrar por:</FilterHeader>

              {filters.map(({ component: Component, placeholder, options, accessor, isMultiSelect, ...props }, index) => {
                const key = `${index}-${accessor}`
                Component = Component || ButtonDropdown
                let value = state[accessor]
                const filter = filters.find(({ accessor: a }) => a === accessor)
                if (filter) {
                  if (filter.transformer) {
                    value = filter.transformer.format(value)
                  }

                  if (isMultiSelect) {
                    value = value
                      ? value.map((item) => {
                          return options.find((option) => option.value === item)
                        })
                      : value
                  } else {
                    value = options?.find((option) => option.value === value) || value
                  }
                }

                let selected
                if (!Component) {
                  selected = value && options.find((option) => option.value === value)
                }

                return (
                  <Component
                    key={key}
                    selected={selected}
                    placeholder={placeholder}
                    options={options}
                    isMultiSelect={isMultiSelect}
                    value={value}
                    onClear={handleFilterChange(accessor)}
                    onSubmit={handleFilterChange(accessor)}
                    data-test={`filter-dropdown-${key}`}
                    typeChange={typeChange}
                    {...props}
                  />
                )
              })}
            </FilterSelectorsContainer>
            <FilterActionsContainer>
              {!buttonsDisabled && (
                <Button ghost onClick={handleFiltersClear} data-test='filter-btn'>
                  Limpar Filtro
                </Button>
              )}
            </FilterActionsContainer>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </FilterContainer>
  )
}

Filter.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      placeholder: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      transformer: PropTypes.any,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.oneOf([PropTypes.string, PropTypes.object]).isRequired
        })
      )
    })
  ),
  values: PropTypes.any,
  defaultSearch: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  onSearchSubmit: PropTypes.func,
  onSearchClear: PropTypes.func,
  onFiltersApply: PropTypes.func,
  onFiltersClear: PropTypes.func,
  noSearchInput: PropTypes.bool,
  noDateRange: PropTypes.bool,
  onAmountTypeChange: PropTypes.func,
  typeChange: PropTypes.string,
  handleIdTransaction: PropTypes.func,
  clearIdTransaction: PropTypes.func
}

Filter.defaultProps = {
  onSearchSubmit: () => {
    /* noop */
  },
  onSearchClear: () => {
    /* noop */
  },
  onFiltersApply: () => {
    /* noop */
  },
  onFiltersClear: () => {
    /* noop */
  },
  handleIdTransaction: () => {
    /* noop */
  },
  clearIdTransaction: () => {
    /* noop */
  },
  onAmountTypeChange: null,
  filters: [],
  values: {}
}

export default Filter
