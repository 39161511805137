import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactSVG from 'react-svg'

import { darkerGray, darkGray, red, smallText, white } from 'src/Styles/settings/Constants'
import IconButton from 'src/UIComponents/IconButton/IconButton'
import { ExportProgress } from './ExportProgress'

const ExportActionContainer = ({
  item,
  mouseEnter,
  mouseLeave,
  isTooltipRetry,
  handleDelete,
  handleRetry,
  handleDownload
}) => {
  const clickDelete = () => {
    if (handleDelete) {
      handleDelete(item)
    }
  }

  const clickRetry = () => {
    if (handleRetry) {
      handleRetry(item)
    }
  }

  const clickDownload = () => {
    if (handleDownload) {
      window.location.href = item.url
    }
  }

  return (
    <ActionsContainer>
      {(item.status === 2 || item.error) &&
        <>
          <DeleteButton
            onClick={clickDelete}
            data-tracking={`ExportNotification > Remove`}
            data-test='export-notification-item-delete-btn'
            white
          >
            <IconDelete src={'/assets/icons/actions/delete.svg'} />
          </DeleteButton>
          {
            !item.error
              ? <DownloadButton
                white
                onClick={clickDownload}
                data-tracking={`ExportNotification > Download`}
                data-test='export-notification-item-download-btn'
              >
                <IconDownload src={'/assets/icons/actions/download.svg'} />
              </DownloadButton>
              : <RetryButton
                onClick={clickRetry}
                white
                onMouseEnter={mouseEnter}
                onMouseLeave={mouseLeave}
                data-tracking={`ExportNotification > Download > Retry`}
                data-test='export-notification-item-retry-btn'
              >
                <IconRetry src={'/assets/icons/actions/reload.svg'} />
              </RetryButton>
          }
        </>
      }
      {(item.status === 0 || item.status === 1) &&
        <ExportProgress
          percentage={item.percentage}
        />
      }

      {isTooltipRetry &&
        <TooltipUp>Tentar Novamente</TooltipUp>
      }
    </ActionsContainer>
  )
}

ExportActionContainer.propTypes = {
  item: PropTypes.object,
  mouseEnter: PropTypes.func,
  mouseLeave: PropTypes.func,
  handleDelete: PropTypes.func,
  handleRetry: PropTypes.func,
  handleDownload: PropTypes.func,
  isTooltipRetry: PropTypes.bool
}

export { ExportActionContainer }

const ActionsContainer = styled.div`
  display: flex;
  position: relative;
`
const IconDownload = styled(ReactSVG)`
  svg {
    fill: ${props => props.theme.color};
  }
`
const IconDelete = styled(ReactSVG)`
  svg {
    fill: ${darkGray};
  }
`
const IconRetry = styled(ReactSVG)`
  svg {
    fill: ${red};
  }
`
const DownloadButton = styled(IconButton)`
  border-color: ${props => props.theme.color};
  width: 30px;
  height: 30px;
  align-self: center;
  cursor: pointer;
`
const RetryButton = styled(IconButton)`
  border-color: ${red};
  width: 30px;
  height: 30px;
  align-self: center;
  cursor: pointer;
`
const DeleteButton = styled(IconButton)`
  border-color: ${darkGray};
  width: 30px;
  height: 30px;
  align-self: center;
  cursor: pointer;
  margin-right: 10px;
  opacity: 0;
  visibility: hidden;
`
const TooltipUp = styled.div`
  text-align: center;
  position: absolute;
  background: ${darkerGray};
  border-radius: 4px;
  padding: 5px;
  color: ${white};
  font-size: ${smallText};
  top: -12px;
  left: -36px;
  width: auto;
  transform: translateY(-50%);
  white-space: pre;
  z-index: 9999;

  :before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid ${darkerGray};
    right: 14px;
    bottom: -9px;
    transform: rotate(90deg);
  }
`
