import * as PropTypes from 'prop-types'
import { DropdownToggle } from 'reactstrap'
import styled from 'styled-components'

const StyledDropdownToggle = styled(DropdownToggle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: ${({ size }) => size};
  cursor: pointer;
`

StyledDropdownToggle.propTypes = {
  size: PropTypes.string
}

export default StyledDropdownToggle
