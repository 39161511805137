import React from 'react'
import * as PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'
import {
  textBold,
  smallText,
  largePadding,
  bigPadding,
  margin,
  smallMargin,
  red
} from 'src/Styles/settings/Constants'

const ErrorState = ({
  iconSrc,
  iconColor,
  title,
  description,
  color
}) => (
  <ErrorStateContainer color={color}>
    <StyledSVG src={iconSrc} iconColor={iconColor} color={color} />
    <Title>{title}</Title>
    <Description>{description}</Description>
  </ErrorStateContainer>
)

const ErrorStateContainer = styled.div`
  font-size: ${smallText};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${largePadding} ${bigPadding};
  margin-bottom: ${margin};
  color: ${({ color }) => color || red};
`

const StyledSVG = styled(ReactSVG)`
  width: 40px;
  height: 40px;
  margin-bottom: ${smallMargin};

  ${({ iconColor }) => iconColor && css`
    svg {
      width: 100%;
      height: 100%;
      fill: ${iconColor}
    }
  `}
`

const Title = styled.p`
  font-weight: ${textBold};
  text-transform: uppercase;
`

const Description = styled.p`
`

ErrorState.propTypes = {
  iconSrc: PropTypes.string,
  iconColor: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string
}

export default ErrorState
