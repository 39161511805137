import React from 'react'
import { Tooltip } from '../Tooltip'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { darkGray, white } from '../../Styles/settings/Constants'

export default function TooltipComponent ({ id, children, text, className = '', icon = false }) {
  if (icon) {
    return (
      <>
        <Tooltip id={id}>{text}</Tooltip>
        <TooltipContainer className={`${className}`}>
          {children}
          <InfoIcon data-tip data-for={id}>?</InfoIcon>
        </TooltipContainer>
      </>
    )
  } else {
    return (
      <>
        <Tooltip id={id}>{text}</Tooltip>
        <div data-tip data-for={id} className={className}>
          {children}
        </div>
      </>
    )
  }
}

const TooltipContainer = styled.div`
  display: flex !important;
  position: relative;
  justify-content: flex-end;
  align-items: center;
`
const InfoIcon = styled.div`
  padding: 0px 3px;
  background-color: ${darkGray};
  color: ${white};
  border-radius: 50%;
  font-size: 8px;
  margin-left: 5px;
  margin-bottom: 1px;
  font-weight: bold;
`

TooltipComponent.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.any,
  text: PropTypes.string.isRequired
}
