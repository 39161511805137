import { DropdownMenu } from 'reactstrap'
import styled, { css } from 'styled-components'

import { smallText, white, smallPadding } from 'src/Styles/settings/Constants'

const StyledDropdownMenu = styled(DropdownMenu)`
  && {
    z-index: 1999;
    border: none;
    border-radius: 5px;
    min-width: 100%;
    margin-top: 5px;
    background-color: ${white};
    white-space: pre;
    font-size: ${smallText};
    padding-bottom: unset;
    outline: none;

    ul {
      padding: 0 ${smallPadding};
      ${({ noPadding }) => noPadding && css`
        padding: 0 !important;
      `}
    }
  }
`

export default StyledDropdownMenu
