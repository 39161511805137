import React from 'react'
import * as PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'
import { useDispatch } from 'react-redux'

import {
  textBold,
  smallText,
  largePadding,
  bigPadding,
  smallMargin,
  red
} from 'src/Styles/settings/Constants'

const ErrorStateCardUI = ({
  iconSrc,
  iconColor,
  title,
  description,
  color,
  action
}) => {
  const dispatch = useDispatch()

  return (
    <ErrorStateCardContainer onClick={() => dispatch(action())} color={color}>
      <StyledSVG src={iconSrc} iconColor={iconColor} color={color} />
      <Title>{title || 'Desculpe, tivemos um problema'}</Title>
      <Description>{description || 'Clique aqui para recarregar.'}</Description>
    </ErrorStateCardContainer>
  )
}

const ErrorStateCardContainer = styled.div`
  font-size: ${smallText};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${largePadding} ${bigPadding};
  cursor: pointer;
  color: ${({ color }) => color || red};
`

const StyledSVG = styled(ReactSVG)`
  width: 19px;
  height: 19px;
  margin-bottom: ${smallMargin};

  ${({ iconColor }) => iconColor && css`
    svg {
      width: 100%;
      height: 100%;
      fill: ${iconColor}
    }
  `}
`

const Title = styled.p`
  font-weight: ${textBold};
`

const Description = styled.p`
  cursor: pointer;
`

ErrorStateCardUI.propTypes = {
  iconSrc: PropTypes.string,
  iconColor: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
  action: PropTypes.func
}

export default ErrorStateCardUI
