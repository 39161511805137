import styled from 'styled-components'
import { smallMargin } from 'src/Styles/settings/Constants'

const FilterActionsContainer = styled.div`
  display: inline;
  width: 100%;

  button {
    width: auto;
    margin-right: ${smallMargin};
  }
`

export default FilterActionsContainer
