import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'
import onClickOutside from 'react-onclickoutside'

import {
  textBold,
  white,
  smallMargin,
  darkGray,
  margin,
  normalText,
  darkerGray
} from 'src/Styles/settings/Constants'

const SimpleDropdown = ({ value, options, handleSelect }) => {
  const [show, setShow] = useState(false)

  SimpleDropdown.handleClickOutside = () => setShow(false)

  const handleSelectOption = (option) => {
    handleSelect(option)
    setShow(false)
  }

  return (
    <Container>
      <Selected onClick={() => setShow(!show)}>
        {value}
        <Icon src='/assets/icons/arrow/medium-arrow-down.svg' />
      </Selected>
      {show && <Options>
        {
          options.map(option => <Option selected={value === option} onClick={() => handleSelectOption(option)}>{option}</Option>)
        }
      </Options>}
    </Container>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => SimpleDropdown.handleClickOutside
}

SimpleDropdown.defaultSize = {
  options: []
}

SimpleDropdown.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array,
  handleSelect: PropTypes.func
}

const dropdown = onClickOutside(SimpleDropdown, clickOutsideConfig)

export { dropdown as SimpleDropdown }

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${margin};
  position: relative;
`

const Options = styled.div`
  position: absolute;
  top: 20px;
  min-width: 60px;
  background-color: ${white};
  z-index: 9;
  border-radius: 5px;
  color: ${darkGray};
  box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.07);
  font-size: ${normalText};
  text-align: left;
`

const Selected = styled.div`
  font-weight: ${textBold};
  margin-left: ${smallMargin};
  display: flex;
  font-size: ${normalText};
  color: ${darkGray};
  
  :hover {
    cursor: pointer;
  }
`

const Icon = styled(ReactSVG)`
  font-size: 10px;
  margin-left: ${smallMargin};
  align-self: center;

  svg {
    fill: ${darkGray};
  }
`

const Option = styled.div`
  padding: 0 1rem;
  margin: 0.75rem 0;

  ${({ selected }) => selected && css`
    color: ${props => props.theme.color};
    font-weight: ${textBold};
  `}

  :hover {
    cursor: pointer;
    color: ${darkerGray};
    font-weight: ${textBold};
    border-left: 2px solid ${props => props.theme.color};
    padding-left: 0.8rem;
  }
`
