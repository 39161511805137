import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import * as PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import { getFileExtensionIcon } from '../../Utils/Utils'
import Label from 'src/UIComponents/Label/Label'
import { ExportActionContainer as ActionsContainer } from 'src/UIComponents/ExportNotification/ExportActionContainer'

import { darkGray, red, lightGray, smallerText } from 'src/Styles/settings/Constants'

const ExportNotificationListItem = ({ item, handleDelete, handleRetry, handleDownload }) => {
  // Cria flag de error
  item.error = item.status === -1

  const [isTooltipRetry, setTooltipRetry] = useState(false)

  const mouseEnter = () => {
    setTooltipRetry(true)
  }
  const mouseLeave = () => {
    setTooltipRetry(false)
  }
  return (
    <Item shouldHover={item.status === 2 || item.error} data-test={`export-notification-item-${item.filename}`}>
      <TitleContainer>
        <IconExtension
          error={item.error ? item.error : undefined}
          src={getFileExtensionIcon(item.extension)} />
        <Filename
          color={item.error ? red : darkGray}
        >
          {item.filename.split('.')[0]}
          {item.error && <ErrorMessage>Algo deu errado na exportação</ErrorMessage>}
        </Filename>
      </TitleContainer>
      <ActionsContainer
        item={item}
        mouseEnter={mouseEnter}
        mouseLeave={mouseLeave}
        isTooltipRetry={isTooltipRetry}
        handleDelete={handleDelete}
        handleRetry={handleRetry}
        handleDownload={handleDownload}
      />
    </Item>
  )
}

ExportNotificationListItem.propTypes = {
  item: PropTypes.object,
  handleDelete: PropTypes.func,
  handleRetry: PropTypes.func,
  handleDownload: PropTypes.func
}

const Item = styled.div`
  display: flex;
  height: 60px;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;

  ${({ shouldHover }) => shouldHover && css`
    :hover {
        background-color: ${lightGray};
    }
  `}


  :hover div:last-child > button {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s;
  }
`
const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 0;
`
const ErrorMessage = styled.div`
  font-size: ${smallerText};
`
const Filename = styled(Label)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
`
const IconExtension = styled(ReactSVG)`
  margin-right: 10px;
  align-self: center;
  ${({ error }) => error && `
    svg {
      fill: ${red}
    }
  `}
`

export { ExportNotificationListItem }
