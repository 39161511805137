import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { isEmpty, isEqual } from 'lodash'

import {
  StyledDropdownToggle as DropdownToggle,
  StyledDropdownMenu as DropdownMenu,
  StyledButtonDropdown
} from './'
import CheckboxForm from 'src/UIComponents/Checkbox/CheckboxForm'
import Button from 'src/UIComponents/Button/ButtonDefault'
import Footer from 'src/UIComponents/Filter/Footer'

import { List } from 'src/UIComponents/Commons/List/List'
import {
  DropdownItem
} from 'reactstrap'

import { ChevronDown, ChevronUp } from 'src/UIComponents/Chevron/Chevron'
import {
  white,
  darkGray,
  normalText,
  lightGray
} from 'src/Styles/settings/Constants'
import IconButton from 'src/UIComponents/Commons/IconButton/IconButton'

const useToggle = () => {
  const [isOpen, setOpen] = useState(false)
  const onToggle = () => setOpen(!isOpen)
  return {
    isOpen,
    onToggle
  }
}

const ButtonDropdown = ({
  label,
  placeholder,
  options,
  value,
  defaultValue,
  isMultiSelect,
  onChange,
  onSubmit,
  onClear,
  hideSubmit,
  noPadding,
  noBackground,
  disable = false
}) => {
  const getValue = () => isMultiSelect ? new Set(value) : value
  const { isOpen, onToggle } = useToggle()
  const [ selected, setSelected ] = useState(getValue())

  const isActive = !isOpen && !isEmpty(selected) && !isEqual(selected, defaultValue) && (isMultiSelect ? !!selected.size : !!selected)

  const printLabel = () => {
    if (!isOpen && selected && selected.size > 0) {
      label = selected.values().next().value?.label
      if (selected.size > 1) {
        label += ` +${selected.size - 1}`
      }
    }

    return (!isOpen && label) || (selected && selected.label ? selected.label : placeholder)
  }

  const handleClear = () => {
    setSelected(isMultiSelect ? new Set() : null)
    onClear()
  }

  const printIcon = () => {
    if (isActive) {
      return (
        <>{
          !disable && (
            <IconButton
              small
              primary
              src='/assets/icons/actions/close.svg'
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleClear()
              }}
            />
          )
        }
        </>
      )
    } else {
      return isOpen
        ? <ChevronUp primary={isActive} />
        : <ChevronDown primary={isActive} />
    }
  }

  const handleChange = value => {
    if (isMultiSelect) {
      selected && selected.has(value)
        ? selected.delete(value)
        : selected.add(value)

      value = new Set(selected)
    }
    setSelected(value)
  }

  useEffect(() => {
    onChange(selected)
  }, [selected])

  useEffect(() => {
    if (!isOpen) { onSubmit(selected) }
  }, [isOpen])

  useEffect(() => {
    setSelected(getValue())
  }, [value])

  return (
    <StyledButtonDropdown isOpen={isOpen} toggle={!disable ? onToggle : null} noBackground={noBackground}>
      <StyledDropdownToggle caret selected={isActive} disable={disable}>
        {printLabel()}
        {printIcon()}
      </StyledDropdownToggle>
      {disable === false && (
        <StyledDropdownMenu noPadding={noPadding}>
          <List>
            {
              options && options.map((option, index) => {
                let {
                  label,
                  component: Component
                } = option
                if (Component) return <Component key={index} onChange={handleChange} />
                const isOptionActive = selected && (
                  isMultiSelect
                    ? selected.has(option)
                    : isEqual(selected, option.value)
                )

                const key = `${index}-${label}`
                const onChange = (e) => {
                  e.preventDefault()
                  handleChange(isMultiSelect ? option : option.value)
                }

                return isMultiSelect ? (
                  <ListItem
                    key={key}
                    className={isOptionActive ? 'active' : ''}
                    divisor={option.divisor}
                    onClick={onChange}
                  >
                    <CheckboxForm
                      name={label}
                      label={label}
                      checked={isOptionActive}
                    />
                  </ListItem>
                ) : (
                  <StyledDropdownItem
                    key={key}
                    className={isOptionActive ? 'active' : ''}
                    divisor={option.divisor}
                    onClick={onChange}
                  >
                    {label}
                  </StyledDropdownItem>
                )
              })
            }
          </List>
          {!hideSubmit &&
          <Footer>
            <Button data-tracking='Selecionar Periodo' link onClick={onToggle}>
          Selecionar
            </Button>
          </Footer>}
        </StyledDropdownMenu>
      )}
    </StyledButtonDropdown>
  )
}

ButtonDropdown.defaultProps = {
  onChange: () => { /* noop */ },
  onSubmit: () => { /* noop */ },
  onClear: () => { /* noop */ },
  placeholder: 'Selecione...'
}

ButtonDropdown.propTypes = {
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  options: PropTypes.arrayOf(PropTypes.object),
  isMultiSelect: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClear: PropTypes.func,
  hideSubmit: PropTypes.bool,
  noPadding: PropTypes.bool,
  noBackground: PropTypes.bool,
  disable: PropTypes.bool
}

const StyledDropdownToggle = styled(DropdownToggle)`
  ::after {
    display: none!important;
    content: none!important;
  }

  > div {
    padding-left: 5px;
  }

  &.btn,
  &.btn:hover,
  &.btn:active,
  &.btn.active,
  &.btn:not(:disabled):not(.disabled):active,
  &.btn:not(:disabled):not(.disabled):active:focus,
  &.btn.dropdown-toggle:focus,
  .show > &.btn.dropdown-toggle,
  .show > &.btn.dropdown-toggle:focus {
    padding: 0.3rem 1rem !important;
    display: flex;
    background: 0;
    color: ${darkGray};
    font-size: ${normalText};
    box-shadow: none;
    border: 1px solid ${white};

    ${({ selected, theme: { color } }) => selected && css`
      color: ${props => props?.disable === true ? darkGray : color};
      border: 1px solid ${props => props?.disable === true ? darkGray : color};

      svg {
        fill: ${props => props?.disable === true ? darkGray : color};
      }
    `}
  }
`

const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  position: relative;
  padding: 0 1.2rem;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }

  &:hover::after {
    content: '';
    width: 2px;
    height: 18px;
    background: ${props => props.theme.color};
    position: absolute;
    top: 2px;
    left: 0;
  }
`

const StyledDropdownItem = styled(DropdownItem)`
  &.dropdown-item,
  &.dropdown-item:hover,
  &.dropdown-item:active,
  &.dropdown-item:active,
  &.dropdown-item:focus,
  &.dropdown-item.active {
    outline: none;
    margin: 4px 0;
    background: 0;
    color: ${darkGray};
    position: relative;
  }

  ${({ divisor }) => divisor && css`
    &.dropdown-item,
    &.dropdown-item:hover,
    &.dropdown-item:active,
    &.dropdown-item:active,
    &.dropdown-item:focus,
    &.dropdown-item.active {
      margin-bottom: 14px;
    }

    &.dropdown-item:before {
      width: calc(100% - 3rem);
      content: '';
      display: block;
      position: absolute;
      bottom: -8px;
      border: 0px solid transparent;
      border-bottom-width: ${({ divisor }) => divisor ? 1 : 0}px;
      border-bottom-color: ${lightGray};
    }
  `}

  &.dropdown-item:hover {
    font-weight: bold;
    &:hover::after {
      content: '';
      width: 2px;
      height: 18px;
      background: ${props => props.theme.color};
      position: absolute;
      top: 5px;
      left: 0;
    }
  }

  &.dropdown-item:active,
  &.dropdown-item.active {
    color: ${({ theme }) => theme.color};
    font-weight: bold;
  }

  cursor: pointer;
  font-size: ${normalText};
`

const StyledDropdownMenu = styled(DropdownMenu)`
  &.dropdown-menu {
    border: 1px solid ${lightGray};
    box-sizing: border-box;
    box-shadow: 0 7px 9px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    padding: 0;
    ${({ noPadding }) => !noPadding && css`
        padding-top: 10px;
    `}
  }
`

export { ButtonDropdown }
