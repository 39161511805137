import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { lightestGray, shadow } from '../../Styles/settings/Constants'
import {
  HeaderExportNotification,
  ExportNotificationList,
  // FooterExportNotification,
  ExportMessage
} from './'

const ExportNotification = ({
  show,
  handleClose,
  items,
  totalSucceeded,
  totalRequested,
  hasError,
  handleDelete,
  handleRetry,
  handleDownload,
  // eslint-disable-next-line no-unused-vars
  handleClearAll,
  listError
}) => {
  const [isMinimized, setIsMinimized] = useState(false)

  return (
    <Wrapper show={show} isMinimized={show && isMinimized}>
      {listError && (
        <ExportMessage
          handleClose={handleClose}
          icon='/assets/icons/alerts/alert.svg'
          message={`${listError.message} | ${listError.status}`}
          title='Ops... parece que ocorreu um erro'
        />
      )}
      {!listError && items.length === 0 ? (
        <ExportMessage handleClose={handleClose} />
      ) : (
        <>
          <HeaderExportNotification
            handleClose={handleClose}
            totalSucceeded={totalSucceeded}
            totalRequested={totalRequested}
            handleMinimize={() => setIsMinimized(!isMinimized)}
            isMinimized={isMinimized}
            hasError={hasError}
          />
          <ExportNotificationList
            items={items}
            isMinimized={isMinimized}
            handleDelete={handleDelete}
            handleRetry={handleRetry}
            handleDownload={handleDownload}
          />
          {/* {items.length > 1 &&
          <FooterExportNotification
            handleClearAll={handleClearAll}
          />
          } */}
        </>
      )}
    </Wrapper>
  )
}

ExportNotification.defaultProps = {
  show: false,
  items: []
}

ExportNotification.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  items: PropTypes.array,
  hasError: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleRetry: PropTypes.func,
  handleDownload: PropTypes.func,
  handleClearAll: PropTypes.func,
  totalSucceeded: PropTypes.number,
  totalRequested: PropTypes.number,
  listError: PropTypes.object
}

const Wrapper = styled.div`
  width: 380px;
  max-height: 342px;
  position: fixed;
  right: 2%;
  bottom: 20px;
  background-color: ${lightestGray};
  box-shadow: none;
  transition:
    transform 0.5s ease-in-out,
    max-height 0.5s ease-in-out;
  z-index: 999999999;
  transform: translateY(calc(100% + 20px));
  border-radius: 5px;

  ${({ show }) =>
    show &&
    css`
      transform: translateY(0);
      box-shadow: 10px 0px 20px 0px ${shadow};
    `}

  ${({ isMinimized }) =>
    isMinimized &&
    css`
      max-height: 52px;
      transform: translateY(0);
    `}
`

export { ExportNotification }
