import styled from 'styled-components'
import FilterHeader from 'src/UIComponents/Filter/FilterHeader'
import DropdownMenu from 'src/UIComponents/Dropdown/StyledDropdownMenu'
import StyledButtonDropdown from 'src/UIComponents/Dropdown/StyledButtonDropdown'
import StyledDropdownToggle from 'src/UIComponents/Dropdown/StyledDropdownToggle'
import FilterLabel from 'src/UIComponents/Filter/FilterLabel'
import FilterSelectorContainer from 'src/UIComponents/Filter/FilterSelectorsContainer'
import { smallMargin, lighterGray, spacePadding, smallPadding, normalText, smallText, white } from 'src/Styles/settings/Constants'

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${props => props.padding ? props.padding : '20px'};
  background: ${lighterGray};
  border-radius: ${props => props.borderRadius ? props.borderRadius : '10px'};

  & .btn-group {
    background: none;
    > button {
      margin-right: ${smallMargin};
      background: ${white} !important;
      border-radius: .5rem;
      transition: width ease-in-out .2s;
    }

    .dropdown-menu  > ul {
      padding: 0;
    }
  }

  ${({ theme }) => theme.mixins.mq.tablet`
    flex-direction: row;
    align-items: center;
  `}

  ${FilterSelectorContainer} {
    display: inline-flex;
  }

  ${FilterHeader} {
    margin-left: ${smallMargin};
    margin-right: ${smallMargin};
    white-space: nowrap;
  }

  ${DropdownMenu} {
    min-width: 150px;
  }

  ${StyledButtonDropdown} {
    margin-bottom: ${smallMargin};
    font-size: ${normalText};

    ${({ theme }) => theme.mixins.mq.tablet`
      margin-bottom: 0;
    `}

    ${StyledDropdownToggle} {
      padding: ${spacePadding} ${smallPadding};

      ${FilterLabel} {
        margin: 0;
        font-size: ${smallText};
      }
    }
  }
`

export default FilterContainer
