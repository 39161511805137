import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'

import {
  white,
  darkerGray,
  smallText,
  red,
  normalText,
  normalPadding,
  spacePadding,
  smallMargin
} from 'src/Styles/settings/Constants'
import { ChevronDown, ChevronUp } from 'src/UIComponents/Chevron/Chevron'
import Label from 'src/UIComponents/Label/Label'

const HeaderExportNotification = ({
  handleClose,
  totalSucceeded,
  totalRequested,
  isMinimized,
  handleMinimize,
  hasError
}) => {
  const [isTooltip, setTooltip] = useState(false)

  const mouseEnter = () => {
    setTooltip(true)
  }

  const mouseLeave = () => {
    setTooltip(false)
  }

  return (
    <HeaderContainer isMinimized={isMinimized} hasError={hasError}>
      <Label
        color={white}
        fontSize={normalText}
        bold
      >
        Arquivos exportados ({totalSucceeded} de {totalRequested})
      </Label>
      <ActionsButtonContainer>
        <MinimizeMaximize data-tracking={`ExportNotification > ${isMinimized ? 'Maximize' : 'Minimize'}`} onClick={handleMinimize}>
          {!isMinimized ? <ChevronDown color={white} /> : <ChevronUp color={white} />}
        </MinimizeMaximize>
        <CloseIcon
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
          data-tracking={`ExportNotification > Close`}
          data-test='export-notification-close-btn'
          onClick={() => {
            setTooltip(false)
            handleClose()
          }}
          src='/assets/icons/actions/close.svg'
        />
        {isTooltip &&
          <TooltipUp>Você será notificado quando o arquivo estiver pronto.</TooltipUp>
        }
      </ActionsButtonContainer>
    </HeaderContainer>
  )
}

HeaderExportNotification.propTypes = {
  handleClose: PropTypes.func,
  totalSucceeded: PropTypes.oneOfType(PropTypes.number),
  totalRequested: PropTypes.oneOfType(PropTypes.number),
  isMinimized: PropTypes.bool,
  handleMinimize: PropTypes.func,
  hasError: PropTypes.bool
}

export { HeaderExportNotification }

const ActionsButtonContainer = styled.div`
  display: flex;
`

const MinimizeMaximize = styled.span`
  cursor: pointer;
`

const CloseIcon = styled(ReactSVG)`
  cursor: pointer;
  margin-left: ${smallMargin};

  svg {
    width: 10px;
    height: 10px;
    fill: ${white};
  }
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.color};
  border-radius: 5px 5px 0px 0px;
  padding: 0 ${normalPadding};
  height: 52px;
  align-items: center;

  ${({ isMinimized }) => isMinimized && css`
    border-radius: 5px;
  `}

  ${({ hasError }) => hasError > 0 && css`
    background-color: ${red}
  `}
`
const TooltipUp = styled.div`
  text-align: center;
  position: absolute;
  background: ${darkerGray};
  border-radius: 4px;
  padding: ${spacePadding};
  color: ${white};
  font-size: ${smallText};
  top: -31px;
  right: 0px;
  width: 196px;
  transform: translateY(-50%);

  :before{
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid ${darkerGray};
    right: 15px;
    bottom: -9px;
    transform: rotate(90deg);
  }
`
