import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { isEqual } from 'lodash'
import {
  darkerGray,
  darkGray,
  gray,
  margin,
  normalText,
  red,
  smallMargin,
  spaceMargin
} from 'src/Styles/settings/Constants'
import ReactSVG from 'react-svg'
import Label from 'src/UIComponents/Label/Label'
import PDFViewer from '../PDFViewer'

const areEqual = (prevProps, nextProps) => {
  const equals = isEqual(
    {
      previewSrc: prevProps.previewSrc,
      externalError: prevProps.externalError,
      fileMaxSizeError: prevProps.fileMaxSizeError,
      fileTypeError: prevProps.fileTypeError,
      type: prevProps.type
    },
    {
      previewSrc: nextProps.previewSrc,
      externalError: nextProps.externalError,
      fileMaxSizeError: nextProps.fileMaxSizeError,
      fileTypeError: nextProps.fileTypeError,
      type: nextProps.type
    }
  )
  return equals
}

const UIDropZone = React.memo(
  ({
    rootProps,
    inputProps,
    fileMaxSizeError,
    fileTypeError,
    type,
    previewSrc,
    changeFileLabel,
    categoryLabel,
    categoryIconSource,
    isDragActive,
    externalError
  }) => (
    <DropzoneContainer
      {...rootProps}
      error={fileMaxSizeError || fileTypeError || externalError}
      isDragActive={isDragActive}
    >
      <Input {...inputProps} />
      <InputZone>
        {previewSrc && type === 'bitmap' && (
          <PreviewImage alt='Preview' src={previewSrc} />
        )}
        {previewSrc && type === 'pdf' && (
          <PDFViewer file={{ url: previewSrc }} />
        )}
        {isDragActive && (
          <DragLabelContainer>
            <Label fontSize={normalText} color={darkerGray}>
              {previewSrc
                ? 'Solte para substituir o arquivo'
                : 'Solte o arquivo aqui'}
            </Label>
          </DragLabelContainer>
        )}
        {previewSrc && !isDragActive && (
          <Label fontSize={normalText} bold>
            {changeFileLabel}
          </Label>
        )}
        {!previewSrc && !isDragActive && (
          <EmptySelectionWrapper>
            <StyledSVG src={categoryIconSource} />
            <StyledLabel marginBottom fontSize={normalText} color={gray}>
              {categoryLabel}
            </StyledLabel>
            <Label
              fontSize={normalText}
              bold
              color={darkGray}
              textAlign='center'
            >
              Arraste para cá
            </Label>
            <Label color={gray} textAlign='center'>
              ou
            </Label>
            <Label fontSize={normalText} bold textAlign='center'>
              Selecione o Arquivo
            </Label>
          </EmptySelectionWrapper>
        )}
      </InputZone>
    </DropzoneContainer>
  ),
  areEqual
)

export const DropzoneContainer = styled.div.attrs(({ isDragActive }) => ({
  className: isDragActive ? 'ignoreOpacity' : undefined
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  outline: none;
  border: 1px dashed ${gray};
  border-radius: 1rem;
  flex: 1;
  cursor: pointer;
  min-height: 120px;

  ${({ error }) =>
    error &&
    css`
      border-color: ${red};
    `}

  ${({ isDragActive }) =>
    isDragActive &&
    css`
      border-color: ${(props) => props.theme.color};
    `}
`

const InputZone = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: ${margin};
  height: 100%;
  width: 100%;

  ${({ error }) =>
    error &&
    css`
      color: ${red};
    `}
`

const EmptySelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 165px;
`

const PreviewImage = styled.img`
  max-width: 100%;
  margin-bottom: ${margin};
`

const StyledLabel = styled(Label)`
  display: block;

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${smallMargin};
    `}
`

const Input = styled.input``

const StyledSVG = styled(ReactSVG)`
  margin-bottom: ${spaceMargin};

  svg {
    width: 40px;
    height: 40px;
    fill: ${gray};
  }
`

const DragLabelContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.95);

  > span {
    max-width: 120px;
  }
`

UIDropZone.defaultProps = {
  type: 'bitmap'
}

UIDropZone.propTypes = {
  rootProps: PropTypes.object,
  inputProps: PropTypes.object,
  fileMaxSizeError: PropTypes.bool,
  fileTypeError: PropTypes.bool,
  previewSrc: PropTypes.string,
  type: PropTypes.oneOf(['bitmap', 'pdf']),
  changeFileLabel: PropTypes.string,
  categoryLabel: PropTypes.string,
  categoryIconSource: PropTypes.string,
  isDragActive: PropTypes.bool,
  externalError: PropTypes.bool
}

export default UIDropZone
