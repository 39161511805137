import styled, { css } from 'styled-components'
import Label from 'src/UIComponents/Input/Label'

const FilterLabel = styled(Label)`
  ${({ primary, theme: { color } }) => primary && css`
    color: ${color};
  `}
`

export default FilterLabel
