import React from 'react'

import Can from 'src/Helpers/Permission/Can'
import EmptyState from 'src/UIComponents/EmptyState/EmptyState'
import Button from '../../UIComponents/Button/ButtonDefault'
import styled from 'styled-components'
import Grid from 'src/UIComponents/Grid/Grid'
import * as PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const EmptyStateDocument = ({ action }) => {
  const {
    themeColor
  } = useSelector(({ custom }) => ({
    themeColor: custom.color
  }))

  return (
    <Container>
      <StyledColumn cols={16} noPadding>
        <StyledEmptyState
          iconSize='big'
          iconColor={themeColor}
          titleColor={themeColor}
          iconSrc='/assets/icons/file/document.svg'
          title='Ainda não recebemos nenhum documento'
          withMarginBottom
          titleSize='big'
          descriptionSize='big'
          description={`Envie os documentos de <strong>identidade, residência e atividade</strong>\ndo estabelecimento para que a gente prossiga com o\nprocesso de credenciamento`} />
      </StyledColumn>
      <Can I='update' a='Seller'>
        <StyledColumn cols={16}>
          <StyledButton
            data-tracking={`Docs > EmptyState`}
            data-test='seller-detail-document-send-btn'
            onClick={action}
          >
            Enviar agora
          </StyledButton>
        </StyledColumn>
      </Can>
    </Container>
  )
}

const StyledButton = styled(Button)`
  width: 134px;
`
const StyledColumn = styled(Grid.Col)`
  text-align-last: center;
  margin: 0px
`
const StyledEmptyState = styled(EmptyState)`
  padding: 0px;
  margin: 0px;
`
const Container = styled.div`
  margin-top: 70px;
`
EmptyStateDocument.propTypes = {
  action: PropTypes.func
}

export default EmptyStateDocument
