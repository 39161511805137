import React from 'react'
import * as PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'
import { Markup } from 'interweave'
import {
  textBold,
  smallText,
  darkGray,
  smallMargin,
  margin
} from 'src/Styles/settings/Constants'
import Button from 'src/UIComponents/Button/ButtonDefault'

const EmptyState = ({
  iconSrc,
  iconColor,
  titleColor,
  title,
  description,
  uppercaseTitle,
  actionTitle,
  action,
  titleSize,
  iconSize,
  descriptionSize,
  marginTop
}) => (
  <EmptyStateContainer marginTop={marginTop} data-test='empty-state'>
    <StyledSVG src={iconSrc} iconColor={iconColor} iconSize={iconSize} />
    <Title
      size={titleSize}
      uppercase={uppercaseTitle}
      titleColor={titleColor}
      data-test='empty-state-title'
    >
      {title}
    </Title>
    <Description size={descriptionSize} data-test='empty-state-description'>
      <Markup content={description} />
    </Description>
    {actionTitle && (
      <Button widthAuto onClick={action} data-test='empty-state-btn'>
        {actionTitle}
      </Button>
    )}
  </EmptyStateContainer>
)

const EmptyStateContainer = styled.div`
  font-size: ${smallText};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 3.8rem 3rem 2rem 3rem;
  color: ${darkGray};

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `}
`

const StyledSVG = styled(ReactSVG)`
  width: ${({ iconSize }) => {
    if (iconSize === 'big') {
      return '40px'
    }
    if (iconSize === 'small') {
      return '25px'
    }
    return '30px'
  }};
  height: ${({ iconSize }) => {
    if (iconSize === 'big') {
      return '40px'
    }
    if (iconSize === 'small') {
      return '25px'
    }
    return '30px'
  }};
  margin-bottom: ${({ iconSize }) =>
    iconSize === 'small' ? '0.8rem' : smallMargin};

  svg {
    width: 100%;
    height: 100%;
  }
  ${({ iconColor }) =>
    iconColor &&
    css`
      path {
        fill: ${iconColor};
      }
    `}
`

const Title = styled.p`
  font-weight: ${textBold};
  white-space: pre-wrap;
  line-height: 20px;

  ${({ withMarginBottom }) =>
    withMarginBottom &&
    css`
      margin-bottom: 1rem;
    `}

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${({ size }) =>
    size === 'big' &&
    css`
      font-size: 1.6rem;
      margin-top: 3rem;
      font-weight: bold;
    `}

  ${({ titleColor }) =>
    titleColor &&
    css`
      color: ${titleColor};
    `}
`

const Description = styled.p`
  white-space: pre-wrap;
  margin-bottom: ${margin};
  line-height: 1.6rem;

  ${({ size }) =>
    size === 'big' &&
    css`
      font-size: 1.4rem;
      line-height: 2rem;
    `}
`

EmptyState.defaultProps = {
  titleSize: 'normal',
  iconSize: 'normal',
  descriptionSize: 'normal'
}

EmptyState.propTypes = {
  iconSrc: PropTypes.string,
  iconColor: PropTypes.string,
  titleColor: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  uppercaseTitle: PropTypes.bool,
  actionTitle: PropTypes.string,
  action: PropTypes.func,
  titleSize: PropTypes.oneOf(['normal', 'big']),
  iconSize: PropTypes.oneOf(['normal', 'big', 'small']),
  descriptionSize: PropTypes.oneOf(['normal', 'big']),
  marginTop: PropTypes.string
}

export default EmptyState
