import styled from 'styled-components'
import { smallText, darkerGray } from '../../Styles/settings/Constants'

const FilterHeader = styled.span`
  font-weight: bold;
  font-size: ${smallText};
  color: ${darkerGray};
  line-height: 31px;
`

export default FilterHeader
