import React from 'react'
import * as PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { ExportNotificationListItem } from './'
import List from 'src/UIComponents/List/List'

const ExportNotificationList = ({
  items,
  handleDelete,
  handleRetry,
  handleDownload,
  isMinimized
}) => {
  const mountList = () => {
    const itemsList = items.map(item => (
      <ExportNotificationListItem
        item={item}
        handleDelete={handleDelete}
        handleRetry={handleRetry}
        handleDownload={handleDownload}
      />
    ))

    return itemsList
  }
  return (
    <ListWrapper isMinimized={isMinimized}>
      <List noRowPadding items={mountList()} />
    </ListWrapper>
  )
}

ExportNotificationList.defaultProps = {
  items: []
}

ExportNotificationList.propTypes = {
  items: PropTypes.array,
  handleDelete: PropTypes.func,
  handleRetry: PropTypes.func,
  handleDownload: PropTypes.func,
  isMinimized: PropTypes.bool
}

export { ExportNotificationList }

const ListWrapper = styled.div`
  max-height: 240px;
  overflow-y: auto;
  transition: max-height 0.5s;

  ${({ isMinimized }) => isMinimized && css`
    max-height: 0;
  `}
`
