import { ButtonDropdown } from 'reactstrap'
import styled, { css } from 'styled-components'
import * as PropTypes from 'prop-types'
import { lightestGray } from '../../Styles/settings/Constants'

const StyledButtonDropdown = styled(ButtonDropdown)`
  background-color: ${({ noBackground }) =>
    noBackground ? 'none' : lightestGray};
  border-radius: 0.4rem;
  ${({ primary, theme: { color } }) =>
    primary &&
    css`
      border: ${`1px solid ${color}`};
    `}
`

StyledButtonDropdown.propTypes = {
  primary: PropTypes.bool
}

export default StyledButtonDropdown
