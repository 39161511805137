import React, { useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import Dots from 'src/UIComponents/Dots/Dots'

import styled from 'styled-components'
import { darkGray, darkerGray, lightGray, smallPadding, smallerText, textBold } from '../../Styles/settings/Constants'

const ExportProgress = ({ percentage }) => {
  const [progressStatusIndex, setProgressStatusIndex] = useState(0)
  const [hasTimeout, setHasTimeout] = useState(false)

  useEffect(() => {
    if (!hasTimeout) {
      setTimeout(() => {
        if (percentage === 0) {
          setProgressStatusIndex(1)
        }
      }, 10000)

      setTimeout(() => {
        if (percentage === 0) {
          setProgressStatusIndex(5)
        }
      }, 60000)
      setHasTimeout(true)
    }
  }, [])

  useEffect(() => {
    if (percentage > 0 && percentage <= 50) {
      setProgressStatusIndex(2)
    } else if (percentage > 50 && percentage <= 75) {
      setProgressStatusIndex(3)
    } else if (percentage > 75 && percentage <= 100) {
      setProgressStatusIndex(4)
    }
  }, [percentage])

  const progressStatus = [
    'Preparando o relatório',
    'Aguarde alguns minutos',
    'Estou a caminho',
    'Já passou da metade!',
    'Estamos quase lá',
    'Em breve você será notificado!'
  ]

  return (
    <Wrapper>
      <Button
        data-tracking={`Report > Open Notification`}
        data-test='report-btn-notifier'
      >
        { progressStatus[progressStatusIndex] }
        <Loading mini outline color={darkerGray} />
      </Button>
    </Wrapper>
  )
}

ExportProgress.defaultProps = {
  percentage: 0
}

ExportProgress.propTypes = {
  percentage: PropTypes.number
}

const Loading = styled(Dots)`
  margin-left: 2rem;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Button = styled.button`
  width: auto;
  background-color: ${lightGray};
  border-radius: 5px;
  border: none;
  font-size: ${smallerText};
  line-height: 14px;
  color: ${darkGray};
  padding: 0 ${smallPadding};
  height: 30px;
  display: flex;
  white-space: nowrap;
  align-items: center;

  > strong {
    font-weight: ${textBold};
    margin: 0 0.3rem;
  }

  &:hover {
    background-color: #E5E5E5;
    transition: all ease-in-out 0.3s;
  }

  :focus {
    outline: none;
  }
`

export { ExportProgress }
