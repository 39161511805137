import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from 'src/UIComponents/Button/ButtonDefault'
import { darkGray, lightGray } from '../../Styles/settings/Constants'

const FooterExportNotification = ({ handleClearAll }) => {
  return (
    <Footer>
      <ButtonStyled
        color={darkGray}
        link
        onClick={handleClearAll}
        data-test='export-notification-clear-btn'
      >
        Limpar tudo
      </ButtonStyled>
    </Footer>
  )
}

FooterExportNotification.propTypes = {
  handleClearAll: PropTypes.func
}

export { FooterExportNotification }

const ButtonStyled = styled(Button)`
  width: auto;
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  border-top: 1px solid ${lightGray};
`
