import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactSVG from 'react-svg'

import { white, darkGray, textBold, bigText, darkerGray, normalText } from 'src/Styles/settings/Constants'

const ExportMessage = ({ icon, title, message, handleClose }) => {
  return (
    <Wrapper>
      <CloseIcon
        onClick={handleClose}
        src='/assets/icons/actions/close.svg'
        data-test='export-notification-message-close-btn'
      />
      <Icon src={icon} />
      <Title>{title}</Title>
      <Message>{message}</Message>
    </Wrapper>
  )
}

ExportMessage.defaultProps = {
  icon: '/assets/icons/alerts/success.svg',
  title: 'Ok! Tudo limpo por aqui',
  message: 'Não existem relatórios a serem baixados.'
}

ExportMessage.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  handleClose: PropTypes.func
}

const Wrapper = styled.div`
  background-color: ${white};
  height: 100%;
  width: 100%;
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  border-radius: 5px;
`

const CloseIcon = styled(ReactSVG)`
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 20px;
  right: 20px;

  div {
    height: 100%;
  }

  svg {
    width: 12px;
    height: 12px;
    fill: ${darkGray};
  }
`

const Icon = styled(ReactSVG)`
  div {
    height: 100%;
    width: 100%;
    text-align: center;
  }

  svg {
    width: 32px;
    height: 32px;
    fill: ${darkerGray};
  }
`

const Title = styled.h1`
  margin-top: ${bigText};
  font-weight: ${textBold};
  font-size: ${bigText};
  line-height: 26px;
  text-align: center;
  color: ${darkGray};
`

const Message = styled.p`
  font-size: ${normalText};
  line-height: 20px;
  text-align: center;
  color: ${darkGray};
  padding: 0 28%;
`

export { ExportMessage }
