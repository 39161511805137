import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { normalText, darkGray, darkerGray, placeholder, red, lightGray } from '../../Styles/settings/Constants'

const FloatingLabelInput = ({ id, disabled, label, type, value, onBlur, isValid, hasError, className, lock, inputRef, ...props }) => (
  <TextInputContainer>
    <FloatingLabelInputContainer disabled={disabled} value={value}>
      <FloatingInput
        id={id}
        onBlur={onBlur}
        type={type}
        hasError={hasError}
        isValid={isValid}
        disabled={disabled}
        value={value || ''}
        className={className}
        ref={inputRef}
        data-test={`input-${id || label}`}
        {...props}
      />
      <FloatingLabel lock={lock} htmlFor={id} hasError={hasError} disabled={disabled}>
        {label}
      </FloatingLabel>
      <LineAnimation />
    </FloatingLabelInputContainer>
  </TextInputContainer>
)

FloatingLabelInput.propTypes = {
  inputRef: PropTypes.any,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  isValid: PropTypes.bool,
  hasError: PropTypes.bool,
  lock: PropTypes.bool,
  props: PropTypes.object,
  className: PropTypes.string
}

const TextInputContainer = styled.article`
  width: 100%;
`

const FloatingLabelInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: 2rem;
  font-size: 2rem;
  border-bottom: ${props => props.disabled ? '0' : '1px'} solid ${lightGray};
`

export const FloatingLabel = styled.label`
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  color: ${darkGray};
  bottom: 5px;
  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  font-size: ${normalText};
  cursor: text;
  pointer-events: none;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${({ disabled }) => disabled && css`
    opacity: 0.5;
  `}

  ${({ lock }) => lock && css`
    &::after {
      content: url('../../assets/icons/others/lock.svg');
      margin-left: 0;
      position: absolute;
    }
  `}
`

const LineAnimation = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: ${props => props.theme.color};
  transition: 0.4s;
`

export const FloatingInput = styled.input`
  padding: 0;
  margin: 0;
  border: none;
  border-bottom: 1px solid transparent;
  outline: none;
  font-size: ${normalText};
  background-color: transparent;
  color: ${darkerGray};
  width: 100%;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: ${darkerGray} !important;

  ${({ disabled }) => disabled && css`
    opacity: 0.5;
  `}

  &::placeholder {
    color: ${placeholder};
    font-style: normal;
    font-size: ${normalText};
  }

  ${({ hasError }) => hasError && css`
    ~ ${LineAnimation} {
      width: 100%;
      background-color: ${red};
      transition: 0.4s;
    }
  `}

  ${({ placeholder }) => placeholder && css`
  + ${FloatingLabel} {
      transform: translate3d(0, -70%, 0) scale(0.80);
    }
  `}

  ${({ showIcon }) => showIcon && css`
    padding-right: 25px
  `}

  &:focus + ${FloatingLabel},
  :not([value='']) + ${FloatingLabel} {
    transform: translate3d(0, -70%, 0) scale(0.80);
  }

  &:-webkit-autofill + ${FloatingLabel} {
    transform: translate3d(0, -70%, 0) scale(0.80);
  }

  &:focus ~ ${LineAnimation} {
    width: 50%;
    transition: 0.4s;
  }

  input[type="password"] {
    -webkit-text-security: disc;
    -moz-text-security: circle;
    text-security: circle;
  }
`

export default FloatingLabelInput
