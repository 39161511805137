import styled from 'styled-components'
import { lightGray } from 'src/Styles/settings/Constants'

const Footer = styled.div`
  box-shadow: 0px -4px 5px ${lightGray};
  margin-top: 2rem;
  button {
    padding: 1.4rem;
  }
`

export default Footer
