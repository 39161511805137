import React from 'react'
import PropTypes from 'prop-types'
import Grid from 'src/UIComponents/Grid/Grid'
import styled, { css } from 'styled-components'

const OpacityGrid = ({
  isDragActive,
  children,
  ...props
}) => (
  <StyledGrid isDragActive={isDragActive} {...props}>
    {children}
  </StyledGrid>
)

const StyledGrid = styled(Grid)`
  ${({ isDragActive }) => css`
    > div > div > div > *:not(.ignoreOpacity) {
      transition: opacity 0.3s;
      opacity: ${isDragActive ? '0.5' : '1'};
    }
  `}
`

OpacityGrid.propTypes = {
  isDragActive: PropTypes.bool,
  children: PropTypes.node
}

export default OpacityGrid
